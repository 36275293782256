import React, { Key, useEffect, useRef, useState } from "react";
import * as apiService from "../services/apiServices";
import {
  Button,
  DatePicker,
  Input,
  InputRef,
  message,
  Modal,
  Popover,
  Space,
  Table,
} from "antd";
import { ServiceDone, ServiceDoneError } from "../types";
import isBetween from "dayjs/plugin/isBetween";
import { displayError, formatDate, formatErrorMessage } from "../Helpers";
import { FilterConfirmProps } from "antd/es/table/interface";
import { SearchOutlined } from "@ant-design/icons";
import type { ColumnsType, ColumnType } from "antd/es/table";
import dayjs from "dayjs";
import ServiceDonesGraph from "./serviceDonesGraph";
import { useTranslation } from "react-i18next";
import { HelpQuestionCircle } from "./styledComponents";

type DataIndex = keyof ServiceDone;

dayjs.extend(isBetween);

const { RangePicker } = DatePicker;

const ServiceDonesTable = ({
  service_id,
  service_done_value_name,
}: {
  service_id: string;
  service_done_value_name: string;
}) => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState("");
  const searchInput = useRef<InputRef>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalCount, setTotalCount] = useState(0);
  const [serviceDoneList, setServiceDoneList] = useState([]);
  const [dateValues, setDateValues] = useState([""]);
  const [sorterOrder, setSorterOrder] = useState("-modified_at");
  const [completed, setCompleted] = useState(null);
  const [hasErrors, setHasErrors] = useState(null);
  const [triggerFetch, setTriggerFetch] = useState(false);
  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex,
  ) => {
    confirm();
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnDateSearchProps = (
    dataIndex: DataIndex,
  ): ColumnType<ServiceDone> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      let dateStrings: string[] = [];
      let startDate = "";
      let endDate = "";
      if (typeof selectedKeys[0] === "string") {
        dateStrings = selectedKeys[0].split(",");
      }
      if (dateStrings.length === 2) {
        startDate = dateStrings[0];
        endDate = dateStrings[1];
      }

      return (
        <div style={{ padding: 8 }}>
          <RangePicker
            popupClassName="calendar-popup"
            style={{ marginBottom: 8, display: "block" }}
            value={[
              startDate ? dayjs(startDate) : null,
              endDate ? dayjs(endDate) : null,
            ]}
            onChange={(dates, dateStrings) => {
              if (dateStrings[0] && dateStrings[1]) {
                const dateStr = dateStrings.join(",");
                setSelectedKeys([dateStr]);
              } else {
                setSelectedKeys([]);
              }
            }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => confirm()}
              icon={<SearchOutlined />}
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters && handleReset(clearFilters);
                handleSearch([], confirm, dataIndex);
              }}
            >
              Reset
            </Button>
          </Space>
        </div>
      );
    },
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      const dateMoment = dayjs(record[dataIndex] as string);
      const dateStrings = (value as string).split(","); // Split the value back into an array
      if (dateStrings.length === 2) {
        const startDate = dateStrings[0];
        const endDate = dateStrings[1];
        const bool = dateMoment.isBetween(startDate, endDate, undefined, "[]");
        return bool;
      }
      return false;
    },
  });

  const getColumnSearchProps = (
    dataIndex: DataIndex,
  ): ColumnType<ServiceDone> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters);
              handleSearch([""], confirm, dataIndex);
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiService.getUserServiceDones({
          customer_service__id: service_id,
          page: currentPage,
          page_size: pageSize,
          is_visible: true,
          value__icontains: searchText,
          date_range: dateValues,
          sort_order: sorterOrder,
          ...(hasErrors !== null && { has_errors: hasErrors }),
          ...(completed !== null && { completed: completed }),
        });
        setServiceDoneList(response.results);
        setTotalCount(response.count);
      } catch (error) {
        console.error(error);
        setServiceDoneList([]);
        setTotalCount(0);
      }
    };
    fetchData();
  }, [
    dateValues,
    pageSize,
    currentPage,
    searchText,
    sorterOrder,
    triggerFetch,
    completed,
    service_id,
    hasErrors,
  ]);
  const columns: ColumnsType<ServiceDone> = [
    {
      title: t(service_done_value_name),
      dataIndex: "value",
      key: "value",
      ...getColumnSearchProps("value"),
    },
    {
      title: t("Last modified"),
      dataIndex: "modified",
      key: "modified",
      render: (modified: string) => formatDate(modified),
      sorter: (a: ServiceDone, b: ServiceDone) => {
        // Using dayjs to compare the date strings
        return dayjs(a.modified).isBefore(dayjs(b.modified)) ? -1 : 1;
      },
      defaultSortOrder: "descend",
      responsive: ["sm"],
      ...getColumnDateSearchProps("modified"),
    },
    {
      title: (
        <span>
          {t("Completed") + " "}
          <HelpQuestionCircle
            text={t(
              "Shows whether the data has been fully copied to the destination system. If the data has been copied, the value is 'Yes'. If the data has not been copied and error has occurred, the value is 'No'. " +
                "Empty value means that it can't be determined whether the copying is completed or not. Such as when the object can be updated in the future or it has been only partially processed.",
            )}
          />
        </span>
      ),
      dataIndex: "completed",
      key: "completed",
      render: (completed: boolean, record: ServiceDone) => {
        if (record.errors && record.errors.length && !completed) {
          return t("No");
        } else if (completed) {
          return t("Yes");
        }
        return "";
      },
      filters: [
        { text: t("Yes"), value: true },
        { text: t("No"), value: false },
      ],
      onFilter: (value: boolean | Key, record) => {
        if (value) {
          return record.completed;
        } else if (!value) {
          return !record.completed;
        }
        return true; // return all records if value is not "true" or "false"
      },
      filterMultiple: false,
      responsive: ["sm"],
    },
    {
      title: t("Errors"),
      dataIndex: "errors",
      key: "errors",
      render: (errors: ServiceDoneError[] | null) => {
        const validErrors = errors?.filter(
          (error) => formatErrorMessage(error.error, t) !== null,
        );
        if (validErrors && validErrors.length > 0) {
          const errorContent = (
            <ul>
              {validErrors.map((error, index) => (
                <li key={index}>{formatErrorMessage(error.error, t)}</li>
              ))}
            </ul>
          );

          return (
            <Popover
              title={t("Error Details")}
              content={errorContent}
              trigger="click"
              overlayStyle={{ maxWidth: "80%" }}
            >
              <Button>
                {t("errors_occurred", { count: validErrors.length })}
              </Button>
            </Popover>
          );
        } else {
          return "";
        }
      },
      filters: [
        { text: t("Has Error"), value: true },
        { text: t("No Error"), value: false },
      ],
      onFilter: (value: boolean | Key, record: ServiceDone) => {
        if (typeof value === "boolean") {
          return value
            ? record.errors !== null && record.errors.length > 0
            : record.errors === null || record.errors.length === 0;
        }
        // Handle unexpected 'value' types if necessary
        return true;
      },

      filterMultiple: false,
    },

    {
      title: t("Delete"),
      key: "delete",
      className: "table-last-cell",
      render: (_, sd) => (
        <Button onClick={() => handleDelete(service_id, sd.id)}>
          {t("Delete")}
        </Button>
      ),
    },
  ];

  const handleDelete = (serviceId: string, sd_id: string) => {
    Modal.confirm({
      title: t("Are you sure you want to delete this?"),

      content: t(
        "A duplicate may occur if you haven't deleted the object at sync destination",
      ),
      onOk: async () => {
        try {
          await apiService.deleteServiceDone(serviceId, sd_id);
          message.success(t("Synchronization record deleted"));
          setTriggerFetch((prev) => !prev);
        } catch (error) {
          message.error(
            t(displayError(error, "Error deleting synchronization record")),
          );
        }
      },

      onCancel() {},
    });
  };
  let locale = {
    emptyText: t("Not found"),
  };
  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
    filters.hasOwnProperty("completed") && filters.completed !== null
      ? setCompleted(filters.completed[0])
      : setCompleted(null);

    filters.hasOwnProperty("errors") && filters.errors !== null
      ? setHasErrors(filters.errors)
      : setHasErrors(null);
    sorter.order === "ascend"
      ? setSorterOrder("modified_at")
      : setSorterOrder("-modified_at");
    filters.value ? setSearchText(filters.value) : setSearchText("");
    filters.modified ? setDateValues(filters.modified) : setDateValues([""]);
  };
  return (
    <div>
      <ServiceDonesGraph service_id={service_id}></ServiceDonesGraph>

      <Table
        columns={columns}
        locale={locale}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: totalCount,
        }}
        dataSource={serviceDoneList}
        onChange={handleTableChange}
      ></Table>
    </div>
  );
};
export default ServiceDonesTable;
